
import ProductTree from "../../components/producttree/ProductTree";
import "./explorer.css" ;

export default function Explorer() {
  return (
    <>
      <div className="WorkflowtreePanel">
        <ProductTree />
      </div>
      
    </>

  );
}
